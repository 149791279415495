import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LeftOutlined } from '@ant-design/icons'
import { Divider, Skeleton, Drawer, Spin, Typography } from 'antd'
import { userCardGetOfficialCardByClassId } from '@/api'
import Card from './card'
import InfiniteScroll from 'react-infinite-scroller'
import { DOMIds } from '@/tools'
import { useRecoilState } from 'recoil'
import { classDetailsOpen as _classDetailsOpen, currentClassDetails as _currentClassDetails } from '@/state'

const AppListContainer = styled.div`
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding-top: 20px;
  width: 100%;
`;

const Title = styled.h2`
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
`;

const ViewAllLink = styled.a`
  color: #007AFF;
  text-decoration: none;
  float: right;
  font-size: 14px;
  cursor: pointer;
`;

export const AppBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`

const Group = memo(({ classData, index, isShowTitle = true }) => {
  const _class = classData?.[index] || {}
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(false) // 加载中
  const [currentClassDetails, setCurrentClassDetails] = useRecoilState(_currentClassDetails)
  const [classDetailsOpen, setClassDetailsOpen] = useRecoilState(_classDetailsOpen)
  const [moreData, setMoreData] = useState([]) // 全部数据
  const [hasMore, setHasMore] = useState(true) // 是否显示更多按钮
  const initPage = { page: 1, limit: 10 }
  const [morePageInfo, setMorePageInfo] = useState(initPage) // 更多列表分页

  // 标记当前组件是否应该显示抽屉
  const shouldShowDrawer = classDetailsOpen && currentClassDetails?._id === _class._id

  useEffect(() => {
    if (classData.length && index !== undefined) {
      const req = {
        page: 1,
        limit: 6,
        filter: {
          classId: [classData[index]._id],
        }
      }

      setLoading(true)
      userCardGetOfficialCardByClassId(req).then(res => {
        setApps(res?.data?.rows || [])
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    }
  }, [classData, index])

  // 查看全部
  const getAll = (_class) => {
    setClassDetailsOpen(true)
    setCurrentClassDetails(_class) // 设置当前选中的分类到全局状态
  }

  // 加载更多
  const loadFunc = async () => {
    try {
      const req = {
        ...morePageInfo,
        filter: {
          classId: [_class._id], // 使用当前组件的_class，而不是依赖于外部的currentClass
        }
      }
      const res = await userCardGetOfficialCardByClassId(req)

      if (!res?.data?.rows?.length || res?.data?.count === moreData.length + res?.data?.rows.length) {
        setHasMore(false)
      }
      setMoreData(v => [...v, ...res?.data?.rows])
      setMorePageInfo(v => ({ ...v, page: v.page + 1}))

    } catch (error) {
      console.error(error)
      setHasMore(false)
    }
  }

  // 重置抽屉相关状态
  useEffect(() => {
    if (!classDetailsOpen) {
      setMoreData([])
      setHasMore(true)
      setMorePageInfo(initPage)
    }
  }, [classDetailsOpen])

  return (
      <AppListContainer id={_class._id}>
        <Divider />

        {
          isShowTitle &&
          <Title>
            <span>{_class?.label}</span>
            <ViewAllLink onClick={() => getAll(_class)}>查看全部</ViewAllLink>
          </Title>
        }

        <AppBox>
          {
            loading ? <Skeleton active={true} /> : (
              <>
                {
                  apps.map((app, index) => {
                    return (
                      <Card key={app._id} app={app} index={index} />
                    )
                  })
                }
              </>
            )
          }
        </AppBox>

        {/* 查看某分类下-全部应用 - 只有当前组件的class被选中时才显示抽屉 */}
        <Drawer
          open={shouldShowDrawer}
          onClose={() => setClassDetailsOpen(false)}
          getContainer={`.${DOMIds['elevator-right']}`}
          destroyOnClose={true}
          mask={false}
          width={'calc(100% * 2 / 3)'}
          style={{ backgroundColor: '#f0f0f0' }}
          closeIcon={<LeftOutlined />}
          // title={currentClass?.label || ''}
          title={currentClassDetails?.label || ''}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={hasMore}
            loader={<Spin tip="Loading"><div style={{ paddingTop: 70 }}/></Spin>}
            useWindow={false}
          >
            {/* <span>共计: {moreData.length} 条</span> */}

            <AppBox>
              {
                moreData?.map((app, index) => (
                  <Card key={app._id} app={app} index={index} />
                ))
              }
            </AppBox>
            {!hasMore && <p style={{ textAlign: 'center', paddingTop: 20 }}><Typography.Text type="secondary" >没有更多数据啦~</Typography.Text></p>}
          </InfiniteScroll>
        </Drawer>
      </AppListContainer>
  );
})

export default Group;